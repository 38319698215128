.main-color {
  background-color: #322348;
  color: white;
}

.hide button {
  visibility: none;
}

.hide:hover button {
  visibility: visible;
}

.autocomplete-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
  border-radius: 5px;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #fafbfc;
}

.rightText {
  text-align: right;
}

.centerText {
  text-align: center;
}

.leftText {
  text-align: left;
}

.action {
  text-align: center;
  vertical-align: middle;
}

.roundButton {
  border-radius: 50%;
}

.vertical-align {
  vertical-align: middle;
  line-height: 100%;
}

/* UCSF banner */
#ucsf {
	background: #000000;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}

#ucsf #topbar-wrapper {
	font-family: "Helvetica", sans-serif;
	font-size: 16px;
}

#ucsf #topbar {
	max-width: 940px;
	margin: 0 auto;
}

#ucsf #topbar ul {
	line-height: 1em;
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: #000033;
    text-align: center;
}

#ucsf #topbar ul li {
	display: inline;
  	font-size: .75em;
  	border-left: 1px solid #FFF;
}

#topbar-wrapper #topbar ul li:first-child {
  	margin-right: 0;
	border-left: none;
}

#topbar-wrapper #topbar ul, #topbar-wrapper #topbar ul li, #topbar-wrapper #topbar ul li a {
  line-height: 1em;
  list-style: none;
  margin: 0;
  padding: 0;
}

#topbar-wrapper #topbar ul li:first-child a {
	background: transparent url(http://pharmchem.ucsf.edu/sites/all/themes/pharmacyoa/images/logo-ucsf.png) no-repeat left 3px;
	display: inline-block;
	margin-left: 0;
	min-height: 28px;
	padding-left: 5.5em;
}

#topbar-wrapper #topbar ul li a {
  color: #FFF;
  margin: 0 .5em;
  padding: 9px 0 0 .5em;
  white-space: nowrap;
}